import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          
          av.ant-gar.de
        </h1>
        <p>
          <a href="https://savecrate.com">
            <strong>save crate</strong>
            <br />
            tag based bookmarking
          </a>
        </p>
        <p>
          <a href="https://whatsmyhood.com">
            <strong>what's my hood?</strong>
            <br />
            answering "what neighborhood is this?"
          </a>
        </p>
        <p>
          <a href="http://wallofadvertisements.com">
            <strong>wall of ads</strong>
            <br />
            can you spot the fake?
          </a>
        </p>
        <p>
          <a href="http://www.playingtonight.nyc">
            <strong>playingtonight.nyc</strong>
            <br />
            nyc live music listing
          </a>
        </p>
        <p>
          <a href="https://www.alexredmon.com">
            created by&nbsp;
            <strong>
              alex redmon
            </strong>
          </a>
        </p>
      </header>

    </div>
  );
}

export default App;
